import service from '@/api/service'
import Resource from '@/api/Resource'

//
// Ruta para la importación de las pólizas,
// la importación de las pólizas pueden ser múltiples.
const IMPORTAR_URL = 'api/importar-poliza-xaml'

const ENDPOINT_NOMINA = 'api/nomina'

const importPolizas = new Resource(IMPORTAR_URL, 'momentosContablesModule')

//
// Funciones para "resetear"
const importPoliza = () => ({
  files: [],
  periodo_fiscal_id: null,
  result: []
})

const importNomina = () => ({
  files: [],
  periodo_fiscal_id: null,
  result: []
})

export default {
  namespaced: true,

  state: {
    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isLoadingResource: false,

    //
    // Objeto para fórmulario,
    // para subir las pólizas
    importPoliza: importPoliza(),

    aplicacionPolizaResponse: [],

    importNomina: importNomina(),

    nominasList: [],

    //
    // Representa el registro de
    // nómina que se está editando actualmente
    currentNomina: null,

    //
    // Flag para indicar que se ha enviado
    // una solicitud de edición del registro de nómina,
    // entonces espera a obtener el item para mostrarlo en el formulario.
    waitToEditItem: false
  },

  getters: {
    getImportPoliza (state) {
      return state.importPoliza
    },

    getImportNomina (state) {
      return state.importNomina
    },

    isCreatingResource (state) {
      return state.isCreatingResource
    },

    isFormPolizaValid (state) {
      return [
        //
        // Valida que el periodo fiscal actual esté activo
        // Boolean(state.importPoliza.periodo_fiscal_id),
        //
        // Valida que el periodo fiscal esté seleccionado
        Boolean(state.importPoliza.files.length > 0)
      ].every(t => t)
    },

    polizaHasResult (state) {
      return Boolean(state.importPoliza.result && state.importPoliza.result.length >= 1)
    },

    getNominaByMonth: state => mes => {
      return state.nominasList.filter(el => el.mes === mes)
    },

    isEditingNomina (state) {
      return Boolean(state.currentNomina)
    },

    isWatingForEditItem (state) {
      return Boolean(state.waitToEditItem)
    },

    getMovimientosOfCurrentNomina (state) {
      return Boolean(state.currentNomina) && state.currentNomina.movimientos
        ? state.currentNomina.movimientos
        : []
    },

    getTiposNomina (state) {
      return state.tiposNominasList
    }
  },

  mutations: {
    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setIsLoadingResouce (state, payload) {
      state.isLoadingResource = payload
    },

    setFilesToImportPoliza (state, payload) {
      state.importPoliza.files = payload
    },

    deleteFileFromImportPoilza (state, payload) {
      const polizas = state.importPoliza.result

      //
      // Validation
      if (polizas.length <= 0) return

      state.importPoliza.result = polizas.filter(
        e => e.content.poliza.folio !== payload.content.poliza.folio
      )
    },

    setPeriodoFiscalToImportPoliza (state, payload) {
      state.importPoliza.periodo_fiscal_id = payload
    },

    setResultToImportPoliza (state, payload) {
      state.importPoliza.result = payload
    },

    resetImportPoliza (state) {
      state.importPoliza = importPoliza()
    },

    setAplicacionPolizaResponse (state, payload) {
      state.aplicacionPolizaResponse = payload
    },

    setNominasList (state, payload) {
      state.nominasList = payload
    },

    setCurrentNomina (state, payload) {
      state.currentNomina = payload
    },

    setWaitToEditItem (state, payload) {
      state.waitToEditItem = payload
    }
  },

  actions: {
    /**
     * Importa una o varias pólizas y almacena el resultado
     * en una propiedad del objeto del cual se está trabajando
     * sus propiedades.
     *
     */
    async importarPolizaXaml ({ commit }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        for (let i = 0; i < payload.files.length; i++) {
          formData.append('files[]', payload.files[i])
        }

        formData.append('periodo_fiscal_id', payload.periodo_fiscal_id)

        const { data } = await importPolizas.create(formData, 'setIsCreatingResource')

        commit('setResultToImportPoliza', data.data)

        //
        // Borra si ha habido alertas anteriores a un proceso
        commit('setAplicacionPolizaResponse', [])

        retval = data
      } catch (error) {
        retval = { error: false, ...error.response.data }
      } finally {
        commit('setIsCreatingResource', false)
      }

      //
      // Elimina los archivos que se han almacenado
      commit('setFilesToImportPoliza', [])

      return retval
    },

    /**
     * aplicarPolizas.
     *
     * Se ejecuta cuando el usuario ha decidido aplicar la previsualización de las pólizas,
     * previsamente subidas.
     */
    async aplicarPolizas ({ state, commit }, payload, modulo) {
      let retval = {}

      try {
        //
        // Adiciona los datos
        payload.periodo_fiscal_id = state.importPoliza.periodo_fiscal_id
        payload.polizas = []
        payload._method = 'PUT'

        for (let i = 0; i < state.importPoliza.result.length; i++) {
          payload.polizas.push(state.importPoliza.result[i])
        }

        const { data } = await service.put(IMPORTAR_URL, payload)

        commit('setFilesToImportPoliza', [])
        commit('setResultToImportPoliza', [])
        commit('setPeriodoFiscalToImportPoliza', null)

        //
        // Guarda la respuesta del proceso de aplicación de las pólizas
        // para mostrarlo al usuario
        commit('setAplicacionPolizaResponse', data.data)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Crea un nuevo registro de nómina.
     *
     * Es necesario para poder realizar la importación y procesarlos.
     *
     * @param {string}  payload.folio
     * @param {date}    payload.fecha
     * @param {string}  payload.concepto
     * @param {int}     payload.mes
     * @param {int}     payload.periodo_fiscal_id
     */
    async crearNomina ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await service.post(ENDPOINT_NOMINA, payload)

        retval = data

        dispatch('listarNominas', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Lista todos los registros de nóminas.
     *
     * Lista los registros de nóminas para visualizarlos en una tabla,
     * y poder hacer modificación al mismo registro, asociar una importación,
     * o eliminar.
     *
     * @param {int}     payload.periodo_fiscal_id
     */
    async listarNominas ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(ENDPOINT_NOMINA, { params })

        retval = data

        commit('setNominasList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getTiposNomina ({ commit }) {
      let retval = {}

      try {
        const { data } = await service.get('api/nomina/tipos')

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getNominaById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT_NOMINA}/${id}`)

        retval = data

        commit('setCurrentNomina', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateNominaById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        payload._method = 'PUT'
        const { data } = await service.put(`${ENDPOINT_NOMINA}/${id}`, payload)

        retval = data

        commit('setCurrentNomina', null)
        dispatch('listarNominas', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Importa un archivo layout a un registro de nómina en específico.
     *
     * @param {file}        payload.file
     * @param {int}         payload.nomina_id
     * @param {int}         payload.periodo_fiscal_id
     * @param {Boolean}     payload.save_file
     * @param {Boolean}     payload.overwrite
     */
    async importarNominas ({ dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('nomina_id', payload.nomina_id)
        formData.append('periodo_fiscal_id', payload.periodo_fiscal_id)
        formData.append('save_file', payload.save_file ? 1 : 0)
        formData.append('overwrite', payload.overwrite ? 1 : 0)

        const { data } = await service.post(`${ENDPOINT_NOMINA}/import`, formData)

        retval = data

        dispatch('listarNominas', payload)
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    },

    /**
     * Obtiene la exportación de un registro de nómina.
     *
     * @param {int}         payload.id Id de la nómina a exportar.
     */
    async exportNominaById ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT_NOMINA}/export`,
          responseType: 'blob',
          params: { nomina_id: id }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportNominaByMes ({ context }, payload) {
      console.log(payload.mes)
      console.log(payload.qna)
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT_NOMINA}/export`,
          responseType: 'blob',
          params: { mes: payload.mes, qna: payload.qna }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaMeses ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis/meses?periodo_fiscal_id=${payload.periodo_fiscal_id}`)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaFuentes ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis/fuentes?periodo_fiscal_id=${payload.periodo_fiscal_id}`)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaById ({ context }, id) {
      let retval = {}

      try {
        const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis?periodo_fiscal_id=1&nomina_id=${id}`)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaByQna ({ context }, payload) {
      let retval = {}

      try {
        if (payload.fuente_financiamiento_id) {
          const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis?periodo_fiscal_id=${payload.periodo_fiscal_id}&mes=${payload.mes}&qna=${payload.qna}&fuente_financiamiento_id=${payload.fuente_financiamiento_id}`)
          retval = data
        } else {
          const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis?periodo_fiscal_id=${payload.periodo_fiscal_id}&mes=${payload.mes}&qna=${payload.qna}`)
          retval = data
        }
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaPropuesta ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis/propuesta?periodo_fiscal_id=${payload.periodo_fiscal_id}&mes=${payload.mes}&qna=${payload.qna}`)
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaEliminarPropuesta ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis/propuesta/delete?periodo_fiscal_id=${payload.periodo_fiscal_id}&mes=${payload.mes}&qna=${payload.qna}`)
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaDisponibilidad ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis/disponibilidad?periodo_fiscal_id=${payload.periodo_fiscal_id}&mes=${payload.mes}&qna=${payload.qna}`)
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async analisisNominaGlobal ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await await service.get(`${ENDPOINT_NOMINA}/analisis?periodo_fiscal_id=1&mes=${payload.mes}`)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateAnalisisNominaById ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await await service.put(`${ENDPOINT_NOMINA}/analisis`, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportPolizaAnalisisNominaById ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT_NOMINA}/analisis/export-poliza`,
          responseType: 'blob',
          params: { periodo_fiscal_id: payload.periodo_fiscal_id, mes: payload.mes, qna: payload.qna }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportSolicitudAnalisisNominaById ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT_NOMINA}/analisis/export-solicitud`,
          responseType: 'blob',
          params: { periodo_fiscal_id: payload.periodo_fiscal_id, mes: payload.mes, qna: payload.qna }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async importarReporteSaldos ({ dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('periodo_fiscal_id', payload.periodo_fiscal_id)
        formData.append('mes', payload.mes)
        formData.append('qna', payload.qna)
        // formData.append('save_file', payload.save_file ? 1 : 0)
        // formData.append('overwrite', payload.overwrite ? 1 : 0)

        const { data } = await service.post(`${ENDPOINT_NOMINA}/analisis/import`, formData)

        retval = data
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    },

    async autorizarNominaById ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service.post('api/nomina/autorizar', {
          nomina_id: payload
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina una nómina por su id.
     *
     * @param {int}         payload.id
     */
    async deleteNominaById ({ dispatch }, { id, data }) {
      let retval = {}

      try {
        const getval = await service.delete(`${ENDPOINT_NOMINA}/${id}`, { data })

        retval = getval.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
