import service from '@/api/service'

const ENDPOINT = 'api/polizas'

export default {
  namespaced: true,

  state: {
    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isLoadingResource: false,

    polizasList: [],

    currentPoliza: null,

    waitToEditItem: false
  },

  getters: {
    isCreatingResource (state) {
      return state.isCreatingResource
    },

    getPolizasByMonth: state => mes => {
      return state.polizasList.filter(el => el.mes === mes)
    },

    isEditingPoliza (state) {
      return Boolean(state.currentPoliza)
    },

    isWatingForEditItem (state) {
      return Boolean(state.waitToEditItem)
    },

    getAsientosOfCurrentPoliza (state) {
      return Boolean(state.currentPoliza) && state.currentPoliza.asientos
        ? state.currentPoliza.asientos
        : []
    }
  },

  mutations: {
    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setIsLoadingResouce (state, payload) {
      state.isLoadingResource = payload
    },

    setPolizasList (state, payload) {
      state.polizasList = payload
    },

    setCurrentPoliza (state, payload) {
      state.currentPoliza = payload
    },

    setWaitToEditItem (state, payload) {
      state.waitToEditItem = payload
    }
  },

  actions: {
    async listar ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(ENDPOINT, { params })

        retval = data

        commit('setPolizasList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/${id}`)

        retval = data

        commit('setCurrentPoliza', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        payload._method = 'PUT'
        const { data } = await service.put(`${ENDPOINT}/${id}`, payload)

        retval = data

        commit('setCurrentTraspaso', null)
        dispatch('listar', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteById ({ dispatch }, { id, data }) {
      let retval = {}

      try {
        const getval = await service.delete(`${ENDPOINT}/${id}`, { data })

        retval = getval.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async downloadAcuse ({ context }, traspaso_id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/acuse`,
          responseType: 'blob',
          params: {
            traspaso_id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
