<template>
  <b-container fluid v-bind="$attrs">
    <div class="d-flex justify-content-center mb-3">
      <b-spinner></b-spinner>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <strong>{{ message }}</strong>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Loading',

  props: {
    message: {
      type: String,
      default: 'Cargando información...'
    }
  }
}
</script>

<style lang="scss" scoped></style>
