<template>
  <div>
    <check-authorization
      :requiresAuthorizations="requiresAuthorizations"
      no-slots
      :show-alert="false"
      #default="{authorized, message}"
    >
      <router-link :to="to" class="nav-link" :class="!authorized ? 'disabled text-muted' : ''">
        <slot></slot>
      </router-link>

      <span
        v-if="showPermissionHelp && !authorized"
        v-b-tooltip.hover.right
        class="nav-link text-muted py-0"
        style="font-size: 10px"
      >
        {{ message }}
      </span>
    </check-authorization>
  </div>
</template>

<script>
export default {
  name: 'SidebarLink',

  props: {
    requiresAuthorizations: {
      type: Array,
      required: true
    },

    to: {
      type: Object,
      required: true
    },

    showPermissionHelp: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
