import Resource from '@/api/Resource'

const permissions = new Resource('/api/permissions', 'permissionModule')

export default {
  namespaced: true,

  state: {
    /**
     * Representan todos los permisos disponibles del sistema.
     *
     * Están en un formato para hacer un checklist para poder
     * listar y actualizar fácilmente.
     */
    permissions: null,

    /**
     * Representa al permiso que se está editando actualmente.
     */
    editingPermission: null,

    /**
     * Representan los permisos del rol actual,
     * cuando se está actualizando un rol.
     *
     * Estos permisos están en un formato de checklist para
     * poder listar y actualizar fácilmente.
     */
    currentRolePermissions: []
  },

  getters: {
    /**
     * Retorna un valor para indicar si se está
     * editando un permiso o no.
     */
    isEditing: state => Boolean(state.editingPermission),

    isSetPermissions: state => Boolean(state.permissions)
  },

  mutations: {
    setPermissions (state, payload) {
      state.permissions = payload
    },

    setCurrentRolePermissions (state, payload) {
      state.currentRolePermissions = payload
    },

    setEditingPermission (state, payload) {
      state.editingPermission = payload
    }
  },

  actions: {
    /**
     * Obtiene todos los permisos.
     *
     * Ejecuta la acción para recuperar todos los permisos,
     * cuando la respuesta es satisfactoria, ejecuta una mutación
     * para almacenarlos localmente.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getPermissions ({ commit }) {
      let retval = {}

      try {
        const { data } = await permissions.get('/api/all-permissions-checklist')
        retval = data
        commit('setPermissions', data.data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Fix: Eliminar función para crear permiso. Los permisos no se podrán crear.
     *
     * Crea un permiso.
     *
     * Crea un nuevo permiso, si la respuesta ha sido satisfactoria
     * ejecuta una acción para obtener todos los permisos.
     *
     * @param {Object} payload.name           Nombre del permiso.
     * @param {Object} payload.description    Nombre del permiso.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async createPermission ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await permissions.create(payload)
        retval = data
        dispatch('getPermissions')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Obtiene un recurso en específico.
     *
     * @param {int} id Id del recurso a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getPermission ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await permissions.show(id)
        retval = data
        commit('setEditingPermission', data.data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     *  Actualiza un permiso.
     *
     * @param {int}     id                    Id del recurso a actualizar.
     * @param {Object}  payload.name          Nombre del permiso a actualizar.
     * @param {Object}  payload.description   Descripción del permiso a actualizar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updatePermission ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await permissions.update(id, payload)
        retval = data
        commit('setEditingPermission', null)
        await dispatch('getPermissions')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Fix: Eliminar función para eliminar permiso. Los permisos no se podrán borrar.
     *
     * Eliminar un permiso dado su id.
     *
     * @param {int} id Id del permiso a eliminar
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deletePermission ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await permissions.delete(id)
        retval = data
        await dispatch('getPermissions')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    }
  }
}
