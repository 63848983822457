<template>
  <div class="small d-flex">
    <!-- Modo de pregunta -->
    <div v-if="deleting.questionMode" class="d-flex justify-content-md-between">
      <!-- Cancelar -->
      <a class="link text-black mr-2" @click="reset">{{ deleting.messageCancel }}</a>

      <!-- Confirmar -->
      <a class="link text-danger" @click="deleteItem">{{ deleting.message }}</a>
    </div>

    <div v-else-if="processing">{{ deleting.processingMessage }}</div>

    <!-- Normal -->
    <div v-else class="d-sm-flex justify-content-end">
      <slot name="edit" :slot-scope="retrieveItem"></slot>
      <check-authorization
        v-if="!$slots.edit"
        :requiresAuthorizations="editPermissions"
        :show-alert="false"
        no-slots
        #default="{ authorized, message }"
      >
        <b-button
          v-if="showEditButton"
          class="btn-sm mr-3 mb-2 mb-sm-0"
          :class="authorized ? 'btn-outline-blue' : ''"
          :disabled="!authorized"
          variant="white"
          @click="retrieveItem(authorized)"
        >
          <span v-if="!authorized" v-b-tooltip.top :title="message">
            Editar
          </span>
          <span v-else>Editar</span>
        </b-button>
      </check-authorization>

      <check-authorization
        :requiresAuthorizations="deletePermissions"
        :show-alert="false"
        no-slots
        #default="{authorized, message }"
      >
        <b-button
          v-if="showDeleteButton"
          class="btn-sm "
          :class="authorized ? 'btn-outline-danger' : ''"
          :disabled="!authorized"
          variant="white"
          @click="setConfirmationMode(authorized)"
        >
          <span v-if="!authorized" v-b-tooltip.top :title="message">
            Eliminar
          </span>
          <span v-else>Eliminar</span>
        </b-button>
      </check-authorization>
    </div>
  </div>
</template>

<script>
export default {
  // Update Delete Controls
  name: 'UDControls',

  props: {
    id: {
      type: Number,
      required: true
    },

    deletePath: {
      type: String,
      required: true
    },

    editPermissions: {
      type: Array,
      required: false
    },

    deletePermissions: {
      type: Array,
      required: false
    },

    hideDeleteAction: {
      type: Boolean,
      required: false,
      default: false
    },

    editPath: {
      type: String,
      required: true
    },

    hideEditAction: {
      type: Boolean,
      required: false,
      default: false
    },

    doubleConfirmation: {
      type: Boolean,
      required: false,
      default: false
    },

    doubleConfirmationParams: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data () {
    return {
      processing: false,

      deleting: {
        mode: false,
        questionMode: false,
        processingMessage: 'Procesando...',
        messageCancel: 'Cancelar',
        message: 'Confirmar'
      },

      showEditButton: false,
      showDeleteButton: false
    }
  },

  watch: {
    hideDeleteAction: {
      immediate: true,
      handler (newVal) {
        this.showDeleteButton = !newVal
      }
    },

    hideEditAction: {
      immediate: true,
      handler (newVal) {
        this.showEditButton = !newVal
      }
    }
  },

  methods: {
    /**
     * Cambia la vista para preguntar al usuario si
     * está seguro de ejecutar la acción de eliminar.
     */
    setConfirmationMode (authorized) {
      if (!authorized) return

      this.deleting.questionMode = true
    },

    /**
     * Elimina un elemento a través de una acción de Vuex.
     *
     * A través vuex y el nombre de una acción se le indica
     * qué función es usada para eliminar el elemento en específico,
     * cuando se termina de ejecutar la acción notifica al usuario
     * y vuelve a un estado inicial.
     */
    async deleteItem () {
      this.deleting.questionMode = false
      this.processing = true

      if (this.doubleConfirmation) return this.deleteItemUsingDoubleConfirmation()

      const getval = await this.$store.dispatch(`${this.deletePath}`, this.id)

      this.onDeleteItem(getval)
    },

    async deleteItemUsingDoubleConfirmation () {
      this.reset()

      const res = await this.modalDoubleConfirmation()

      if (!res) return

      const getval = await this.$store.dispatch(`${this.deletePath}`, {
        id: this.id,
        data: { ...this.doubleConfirmationParams }
      })

      return this.onDeleteItem(getval)
    },

    onDeleteItem (getval) {
      this.$notify(getval, 'Eliminación del elemento')

      this.reset()

      this.$emit('on-deleted', getval)
    },

    /**
     * Obtiene un elemento a través de una acción de Vuex.
     *
     * A través de una prop se le indica la acción que debe ejecutar
     * para obtener un elemento en específico, notifica al usuario
     * cuando se ha obtenido el elemento y después emite un evento
     * con el resultado con la información obtenida.
     */
    async retrieveItem (authorized) {
      if (authorized === false) return

      this.processing = true
      const getval = await this.$store.dispatch(this.editPath, this.id)
      this.processing = false

      this.$notify(getval, 'Obtención el elemento')

      this.$emit('on-got-item', getval)
    },

    /**
     * Regresa a la normalidad los estados del componente.
     */
    reset () {
      this.deleting.mode = false
      this.deleting.questionMode = false
      this.processing = false
    },

    modalDoubleConfirmation () {
      let retval = false

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        retval = await this.$bvModal.msgBoxConfirm(
          '¿Confirmas que deseas continuar con la eliminación del recurso?',
          {
            title: 'Confirmación',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'SÍ',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          }
        )

        resolve(retval)
      })
    },

    showErrorMessage (msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: 'Ha ocurrido un error',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  font-weight: bolder;
}
</style>
