<template>
  <base-view
    icon="activity"
    :title="`Hola ${authUser.nombre}`"
    description="Vista general del sistema"
    header-type="overlap"
    :background="isAdmin ? 'bg-gradient-black-to-blue' : 'bg-gradient-light-to-black'"
  >
    <!-- Main page content-->
    <div class="row">
      <div v-if="$can(['acceso usuarios'])" class="col-xl-4 mb-4">
        <router-link :to="{ name: 'Users' }" class="card lift h-100">
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-primary mb-3" data-feather="shield"></i>
                <h5>Administración de usuarios</h5>
                <div class="text-muted small">
                  Administra los usuarios, roles y permisos sobre el sistema.
                </div>
              </div>
              <img src="@/assets/img/illustrations/browser-stats.svg" style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div>

      <div v-if="$hasRoles(['admin'])" class="col-xl-4 mb-4">
        <router-link :to="{ name: 'PanelDeControl' }" class="card lift h-100 my-cursor">
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-black mb-3" data-feather="tool"></i>
                <h5>Configuración</h5>
                <div class="text-muted small">
                  Configura preferencias personales y ajustes generales.
                </div>
              </div>
              <img src="@/assets/img/illustrations/processing.svg" style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div>

      <div v-if="$can(['importar salarios'])" class="col-xl-4 mb-4">
        <router-link :to="{ name: 'RecursoEstatalPlaneaciones' }" class="card lift h-100" href="#!">
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-green mb-3" data-feather="arrow-right-circle"></i>
                <h5>Planeación</h5>
                <div class="text-muted small">
                  Genera la planeación presupuestal y separación de fondos.
                </div>
              </div>
              <img src="@/assets/img/illustrations/at-work.svg" alt="..." style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div>

      <div v-if="$can(['listar bitacoras de un usuario'])" class="col-xl-4 mb-4">
        <router-link
          :to="{ name: 'Historial', params: { tabIndex: 0 } }"
          class="card lift h-100"
          href="#!"
        >
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-purple mb-3" data-feather="activity"></i>
                <h5>Bitácora</h5>
                <div class="text-muted small">
                  Obtén información de las últimas actividades realizadas.
                </div>
              </div>
              <img src="@/assets/img/illustrations/data-report.svg" alt="..." style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div>

      <!-- <div class="col-xl-4 mb-4">
        <router-link :to="{ name: 'Reportes' }" class="card lift h-100" href="#!">
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-yellow mb-3" data-feather="bar-chart"></i>
                <h5>Reportes</h5>
                <div class="text-muted small">Obtén y genera reportes.</div>
              </div>
              <img src="@/assets/img/illustrations/statistics.svg" alt="..." style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div> -->

      <div class="col-xl-4 mb-4">
        <router-link
          :to="{ name: 'Plantillas', params: { tabIndex: 0 } }"
          class="card lift h-100"
          href="#!"
        >
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-red mb-3" data-feather="layers"></i>
                <h5>Plantillas</h5>
                <div class="text-muted small">
                  Descarga las plantillas utilizadas para los procesos de importación.
                </div>
              </div>
              <img src="@/assets/img/illustrations/statistics.svg" alt="..." style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Content-->
    <div class="row">
      <div class="col-xxl-8">
        <!-- <div class="card mb-4">
            <div class="card-header border-bottom">
              <ul class="nav nav-tabs card-header-tabs" id="dashboardNav" role="tablist">
                <li class="nav-item mr-1">
                  <a
                    class="nav-link active"
                    id="overview-pill"
                    href="#overview"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="overview"
                    aria-selected="true"
                  >
                    Overview
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="activities-pill"
                    href="#activities"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="activities"
                    aria-selected="false"
                  >
                    Activities
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="tab-content" id="dashboardNavContent">
                <div
                  class="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview-pill"
                >
                  <div class="chart-area mb-4 mb-lg-0" style="height: 20rem">
                    <canvas id="myAreaChart" width="100%" height="30"></canvas>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="activities"
                  role="tabpanel"
                  aria-labelledby="activities-pill"
                >
                  <div class="datatable table-responsive">
                    <table
                      class="table table-bordered table-hover"
                      id="dataTableActivity"
                      width="100%"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Event</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th>Date</th>
                          <th>Event</th>
                          <th>Time</th>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>01/13/20</td>
                          <td>
                            <i class="mr-2 text-green" data-feather="zap"></i>
                            Server online
                          </td>
                          <td>1:21 AM</td>
                        </tr>
                        <tr>
                          <td>01/13/20</td>
                          <td>
                            <i class="mr-2 text-red" data-feather="zap-off"></i>
                            Server restarted
                          </td>
                          <td>1:00 AM</td>
                        </tr>
                        <tr>
                          <td>01/12/20</td>
                          <td>
                            <i class="mr-2 text-purple" data-feather="shopping-cart"></i>
                            New order placed! Order #
                            <a href="#!">1126550</a>
                          </td>
                          <td>5:45 AM</td>
                        </tr>
                        <tr>
                          <td>01/12/20</td>
                          <td>
                            <i class="mr-2 text-blue" data-feather="user"></i>
                            Valerie Luna submitted
                            <a href="#!">quarter four report</a>
                          </td>
                          <td>4:23 PM</td>
                        </tr>
                        <tr>
                          <td>01/12/20</td>
                          <td>
                            <i class="mr-2 text-yellow" data-feather="database"></i>
                            Database backup created
                          </td>
                          <td>3:51 AM</td>
                        </tr>
                        <tr>
                          <td>01/12/20</td>
                          <td>
                            <i class="mr-2 text-purple" data-feather="shopping-cart"></i>
                            New order placed! Order #
                            <a href="#!">1126549</a>
                          </td>
                          <td>1:22 AM</td>
                        </tr>
                        <tr>
                          <td>01/11/20</td>
                          <td>
                            <i class="mr-2 text-blue" data-feather="user-plus"></i>
                            New user created:
                            <a href="#!">Sam Malone</a>
                          </td>
                          <td>4:18 PM</td>
                        </tr>
                        <tr>
                          <td>01/11/20</td>
                          <td>
                            <i class="mr-2 text-purple" data-feather="shopping-cart"></i>
                            New order placed! Order #
                            <a href="#!">1126548</a>
                          </td>
                          <td>4:02 PM</td>
                        </tr>
                        <tr>
                          <td>01/11/20</td>
                          <td>
                            <i class="mr-2 text-purple" data-feather="shopping-cart"></i>
                            New order placed! Order #
                            <a href="#!">1126547</a>
                          </td>
                          <td>3:47 PM</td>
                        </tr>
                        <tr>
                          <td>01/11/20</td>
                          <td>
                            <i class="mr-2 text-green" data-feather="zap"></i>
                            Server online
                          </td>
                          <td>1:19 AM</td>
                        </tr>
                        <tr>
                          <td>01/11/20</td>
                          <td>
                            <i class="mr-2 text-red" data-feather="zap-off"></i>
                            Server restarted
                          </td>
                          <td>1:00 AM</td>
                        </tr>
                        <tr>
                          <td>01/10/20</td>
                          <td>
                            <i class="mr-2 text-purple" data-feather="shopping-cart"></i>
                            New order placed! Order #
                            <a href="#!">1126547</a>
                          </td>
                          <td>5:31 PM</td>
                        </tr>
                        <tr>
                          <td>01/10/20</td>
                          <td>
                            <i class="mr-2 text-purple" data-feather="shopping-cart"></i>
                            New order placed! Order #
                            <a href="#!">1126546</a>
                          </td>
                          <td>12:13 PM</td>
                        </tr>
                        <tr>
                          <td>01/10/20</td>
                          <td>
                            <i class="mr-2 text-blue" data-feather="user"></i>
                            Diane Chambers submitted
                            <a href="#!">quarter four report</a>
                          </td>
                          <td>10:56 AM</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        <!-- Illustration dashboard card example-->
        <!-- <div class="dev card mb-4">
            <div class="card-body py-5">
              <div class="d-flex flex-column justify-content-center">
                <img
                  class="img-fluid mb-4"
                  src="@/assets/img/illustrations/data-report.svg"
                  alt=""
                  style="height: 10rem"
                />
                <div class="text-center px-0 px-lg-5">
                  <h5>New reports are here! Generate custom reports now!</h5>
                  <p class="mb-4">
                    Our new report generation system is now online. You can start creating custom
                    reporting for any documents available on your account.
                  </p>
                  <a class="btn btn-primary p-3" href="#!">Get Started</a>
                </div>
              </div>
            </div>
          </div> -->
        <div class="row">
          <div class="col-xl-12 mb-4">
            <!-- Dashboard activity timeline example-->
            <history is-for-dashboard></history>
          </div>
          <div class="col-xl-6 mb-4">
            <!-- Pie chart with legend example-->
            <!-- <div class="card h-100">
                <div class="card-header">Traffic Sources</div>
                <div class="card-body">
                  <div class="chart-pie mb-4">
                    <canvas id="myPieChart" width="100%" height="50"></canvas>
                  </div>
                  <div class="list-group list-group-flush">
                    <div
                      class="list-group-item d-flex align-items-center justify-content-between small px-0 py-2"
                    >
                      <div class="mr-3">
                        <i class="fas fa-circle fa-sm mr-1 text-blue"></i>
                        Direct
                      </div>
                      <div class="font-weight-500 text-dark">55%</div>
                    </div>
                    <div
                      class="list-group-item d-flex align-items-center justify-content-between small px-0 py-2"
                    >
                      <div class="mr-3">
                        <i class="fas fa-circle fa-sm mr-1 text-purple"></i>
                        Social
                      </div>
                      <div class="font-weight-500 text-dark">15%</div>
                    </div>
                    <div
                      class="list-group-item d-flex align-items-center justify-content-between small px-0 py-2"
                    >
                      <div class="mr-3">
                        <i class="fas fa-circle fa-sm mr-1 text-green"></i>
                        Referral
                      </div>
                      <div class="font-weight-500 text-dark">30%</div>
                    </div>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div class="col-xxl-4">
        <!-- <div class="row">
            <div class="col-xl-6 col-xxl-12">
              <div class="card mb-4">
                <div class="card-header">People</div>
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center flex-shrink-0 mr-3">
                      <div class="avatar avatar-xl mr-3 bg-gray-200">
                        <img
                          class="avatar-img img-fluid"
                          src="@/assets/img/illustrations/profiles/profile-1.png"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a class="text-dark line-height-normal mb-1" href="#!">
                          Sid Rooney
                        </a>
                        <div class="small text-muted line-height-normal">
                          Position
                        </div>
                      </div>
                    </div>
                    <div class="dropdown no-caret">
                      <button
                        class="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople1"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right animated--fade-in-up"
                        aria-labelledby="dropdownPeople1"
                      >
                        <a class="dropdown-item" href="#!">Action</a>
                        <a class="dropdown-item" href="#!">Another action</a>
                        <a class="dropdown-item" href="#!">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center flex-shrink-0 mr-3">
                      <div class="avatar avatar-xl mr-3 bg-gray-200">
                        <img
                          class="avatar-img img-fluid"
                          src="@/assets/img/illustrations/profiles/profile-2.png"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a class="text-dark line-height-normal mb-1" href="#!">
                          Keelan Garza
                        </a>
                        <div class="small text-muted line-height-normal">
                          Position
                        </div>
                      </div>
                    </div>
                    <div class="dropdown no-caret">
                      <button
                        class="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople2"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right animated--fade-in-up"
                        aria-labelledby="dropdownPeople2"
                      >
                        <a class="dropdown-item" href="#!">Action</a>
                        <a class="dropdown-item" href="#!">Another action</a>
                        <a class="dropdown-item" href="#!">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center flex-shrink-0 mr-3">
                      <div class="avatar avatar-xl mr-3 bg-gray-200">
                        <img
                          class="avatar-img img-fluid"
                          src="@/assets/img/illustrations/profiles/profile-3.png"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a class="text-dark line-height-normal mb-1" href="#!">
                          Kaia Smyth
                        </a>
                        <div class="small text-muted line-height-normal">
                          Position
                        </div>
                      </div>
                    </div>
                    <div class="dropdown no-caret">
                      <button
                        class="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople3"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right animated--fade-in-up"
                        aria-labelledby="dropdownPeople3"
                      >
                        <a class="dropdown-item" href="#!">Action</a>
                        <a class="dropdown-item" href="#!">Another action</a>
                        <a class="dropdown-item" href="#!">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center flex-shrink-0 mr-3">
                      <div class="avatar avatar-xl mr-3 bg-gray-200">
                        <img
                          class="avatar-img img-fluid"
                          src="@/assets/img/illustrations/profiles/profile-4.png"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a class="text-dark line-height-normal mb-1" href="#!">
                          Kerri Kearney
                        </a>
                        <div class="small text-muted line-height-normal">
                          Position
                        </div>
                      </div>
                    </div>
                    <div class="dropdown no-caret">
                      <button
                        class="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople4"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right animated--fade-in-up"
                        aria-labelledby="dropdownPeople4"
                      >
                        <a class="dropdown-item" href="#!">Action</a>
                        <a class="dropdown-item" href="#!">Another action</a>
                        <a class="dropdown-item" href="#!">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center flex-shrink-0 mr-3">
                      <div class="avatar avatar-xl mr-3 bg-gray-200">
                        <img
                          class="avatar-img img-fluid"
                          src="@/assets/img/illustrations/profiles/profile-5.png"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a class="text-dark line-height-normal mb-1" href="#!">
                          Georgina Findlay
                        </a>
                        <div class="small text-muted line-height-normal">
                          Position
                        </div>
                      </div>
                    </div>
                    <div class="dropdown no-caret">
                      <button
                        class="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople5"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right animated--fade-in-up"
                        aria-labelledby="dropdownPeople5"
                      >
                        <a class="dropdown-item" href="#!">Action</a>
                        <a class="dropdown-item" href="#!">Another action</a>
                        <a class="dropdown-item" href="#!">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center flex-shrink-0 mr-3">
                      <div class="avatar avatar-xl mr-3 bg-gray-200">
                        <img
                          class="avatar-img img-fluid"
                          src="@/assets/img/illustrations/profiles/profile-6.png"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a class="text-dark line-height-normal mb-1" href="#!">
                          Wilf Ingram
                        </a>
                        <div class="small text-muted line-height-normal">
                          Position
                        </div>
                      </div>
                    </div>
                    <div class="dropdown no-caret">
                      <button
                        class="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople6"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right animated--fade-in-up"
                        aria-labelledby="dropdownPeople6"
                      >
                        <a class="dropdown-item" href="#!">Action</a>
                        <a class="dropdown-item" href="#!">Another action</a>
                        <a class="dropdown-item" href="#!">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-6 col-xxl-12">
              <div class="card card-header-actions mb-4">
                <div class="card-header">
                  Projects
                  <a class="small text-arrow-icon" href="#!">
                    Create New
                    <i data-feather="arrow-right"></i>
                  </a>
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between small mb-1">
                    <div class="font-weight-bold">Server Setup</div>
                    <div class="small">25%</div>
                  </div>
                  <div class="progress mb-3">
                    <div
                      class="progress-bar bg-danger"
                      role="progressbar"
                      style="width: 25%"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between small mb-1">
                    <div class="font-weight-bold">Database Migration</div>
                    <div class="small">50%</div>
                  </div>
                  <div class="progress mb-3">
                    <div
                      class="progress-bar bg-warning"
                      role="progressbar"
                      style="width: 50%"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between small mb-1">
                    <div class="font-weight-bold">Version Release</div>
                    <div class="small">75%</div>
                  </div>
                  <div class="progress mb-3">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      style="width: 75%"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between small mb-1">
                    <div class="font-weight-bold">Product Listings</div>
                    <div class="small">100%</div>
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style="width: 100%"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        <!-- Illustration dashboard card example-->
        <!-- <div class="card">
            <div class="card-body text-center p-5">
              <img
                class="img-fluid mb-4"
                src="@/assets/img/illustrations/team-spirit.svg"
                alt=""
                style="max-width: 16.25rem"
              />
              <h5>Team Access</h5>
              <p class="mb-4">
                Upgrade your plan to get access to team collaboration tools.
              </p>
              <a class="btn btn-primary p-3" href="#!">Upgrade</a>
            </div>
          </div> -->
      </div>
    </div>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import History from '@/components/History/History'

export default {
  name: 'AdminDashboard',

  computed: {
    ...mapGetters('userModule', { authUser: 'getAuthUser', isAdmin: 'isAdmin' })
  },

  components: {
    History
  }
}
</script>

<style lang="scss" scoped></style>
