<template>
  <div>
    <b-row v-if="resource">
      <b-col v-if="resource.last_page > 1">
        <b-pagination
          :disabled="disabled"
          v-model="resource.current_page"
          :total-rows="resource.total"
          :per-page="resource.per_page"
          first-number
          last-number
          :aria-controls="ariaControls"
          align="fill"
          @change="change">
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Paginator',

  props: {
    ariaControls: String,

    showPath: String,

    resource: Object,

    disabled: Boolean,

    moreData: Object
  },

  methods: {
    /**
     * Si se van a pasar mas parametros al paginador a parte de
     * la propia pagina, mandar a traves de moreData
     */
    change (page) {
      if (this.moreData) {
        this.$store.dispatch(`${this.showPath}`, {
          page: page,
          ...this.moreData
        })
      } else {
        this.$store.dispatch(`${this.showPath}`, page)
      }
    }
  }
}
</script>
