import Resource from '@/api/Resource'
import service from '@/api/service'

const matriz = new Resource(
  'api/matriz-unidad-administrativa-prestacion',
  'matrizUnidadesAdministrativasModule'
)

export default {
  namespaced: true,

  state: {
    matrix: [],

    currentResource: null,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    getItems: state => {
      return state.matrix.slice(2, state.matrix.length) || []
    },

    getFields: state => {
      return state.matrix[1] || []
    },

    getHeader: state => {
      return state.matrix[0] || []
    },

    isMatrizAvailable: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    }
  },

  mutations: {
    setMatrix (state, payload) {
      state.matrix = payload
    },

    setCurrentResource (state, payload) {
      state.currentResource = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    async getAll ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await matriz.all({ periodo_fiscal_id }, 'setIsGettingResource')
        retval = data
        commit('setMatrix', data.data)
      } catch (error) {
        retval = error.response
      }

      return retval
    },

    async create (_, payload) {
      let retval = {}

      try {
        const { data } = await matriz.create(payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async createAll (_, payload) {
      let retval = {}

      try {
        const { data } = await service.post('api/matriz-unidad-administrativa-prestacion/all', {
          data: payload
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async delete (_, payload) {
      let retval = {}

      try {
        const { data } = await service.delete('api/matriz-unidad-administrativa-prestacion', {
          data: payload
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteAll (_, payload) {
      let retval = {}

      try {
        const { data } = await service.delete('api/matriz-unidad-administrativa-prestacion/all', {
          data: payload
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
