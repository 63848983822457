import service from '../../api/service'
import Resource from '@/api/Resource'

const proyectos = new Resource('/api/proyectos', 'proyectoModule')

export default {
  namespaced: true,

  state: {
    /**
     * Representa a todas las unidades administrativas en el sistema
     */
    proyectos: [],

    currentProyecto: null,

    isCreatingResource: false,

    isGettingResource: true,

    isDeletingResource: false
  },

  getters: {
    getList: state => state.proyectos,

    hasProyectos: state => Boolean(state.proyectos.length > 0),

    getAllProyectos: (state, getters) => (getters.hasProyectos ? state.proyectos : []),

    getAvailableList: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource && state.proyectos.length > 0
    },

    isEditingResource: state => Boolean(state.currentProyecto),

    getCurrentEditing: state => state.currentProyecto
  },

  mutations: {
    setProyectos (state, payload) {
      state.proyectos = payload
    },

    setCurrentProyecto (state, payload) {
      state.currentProyecto = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    async getProyectos ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await proyectos.all({ periodo_fiscal_id }, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setProyectos', data.data)

      return { data, message }
    },

    async getProyectosList ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await service.get('api/proyectos?periodo_fiscal_id=' + periodo_fiscal_id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async createProyecto ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await proyectos.create(payload)

        retval = data

        dispatch('getProyectos', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getProyectoToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await proyectos.show(id)

        retval = data

        commit('setCurrentProyecto', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateProyecto ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await proyectos.update(id, payload)

        retval = data

        commit('setCurrentProyecto', null)

        dispatch('getProyectos', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteProyecto ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await proyectos.delete(id)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async export ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `api/proyectos/export?periodo_id=${payload.periodo_id}&formato=${payload.formato}`,
          responseType: 'blob',
          params: {
            periodo_id: payload.periodo_id,
            formato: payload.formato
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
