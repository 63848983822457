<template>
  <b-form-group
    :description="description"
    :invalid-feedback="invalidFeedback"
    :valid-feedback="validFeedback"
    :label-cols="inline ? 4 : 12"
    :content-cols="inline ? 8 : 12"
  >
    <template #label>
      <span class="text-black small font-weight-bolder">
        {{ title }}
        <span v-if="required" class="text-danger">*</span>
      </span>
    </template>

    <slot></slot>
  </b-form-group>
</template>

<script>
export default {
  name: 'XFormGroup',

  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },

    description: {
      type: String,
      required: false,
      default: ''
    },

    required: {
      type: Boolean,
      required: false,
      default: false
    },

    inline: {
      type: Boolean,
      required: false,
      default: false
    },

    invalidFeedback: {
      type: String,
      required: false,
      default: ''
    },

    validFeedback: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
