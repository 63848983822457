<template>
  <transition name="fade" mode="out-in">
    <b-alert v-model="show" :variant="attr.status" :dismissible="attr.closeable">
      <div class="d-flex align-items-center mb-2">
        <!-- Ícono -->
        <div v-if="attr.icon" class="alert-icon-aside">
          <i v-html="getIcon" class="mr-2 d-flex align-items-center"></i>
        </div>

        <h4 class="alert-heading my-1">{{ attr.title }}</h4>
      </div>

      <div class="d-flex align-items-center">
        {{ attr.message }}
      </div>
    </b-alert>
  </transition>
</template>

<script>
import { EventBus } from '@/EventBus'
import feather from 'feather-icons'

export default {
  name: 'NotificationAlert',

  created () {
    EventBus.$on('on-show-notification-alert', data => {
      this.setNotification(data.title, data.message, data)
    })
  },

  data () {
    return {
      show: false,
      attr: {
        title: '',
        message: '',
        status: 'success',
        icon: 'info',
        closeOnTimeout: true,
        closeable: true
      }
    }
  },

  computed: {
    getIcon () {
      return feather.icons[this.attr.icon].toSvg()
    }
  },

  watch: {
    show: {
      immediate: true,
      handler (show) {
        if (show && this.attr.closeOnTimeout) {
          this.$delay(8000).then(_ => this.close())
        }
      }
    }
  },

  methods: {
    setNotification (title, message, attr) {
      this.show = true
      this.attr = attr
      this.attr.title = title
      this.attr.message = message
    },

    close () {
      this.show = false
    }
  }
}
</script>
