import Resource from '@/api/Resource'

const resource = new Resource('/api/capitulos', 'capitulosModule')

export default {
  namespaced: true,

  state: {
    list: [],

    isGettingResource: true
  },

  getters: {
    hasList: state => Boolean(state.list),

    getList: (state, getters) =>
      getters.hasList ? state.list : [],

    isListAvailable: state => {
      return !state.isGettingResource && state.list.length > 0
    },

    getListForForms: (state, getters) => {
      if (getters.hasList) {
        const listForForms = []

        getters.getList.forEach(element => {
          listForForms.push({
            label: element.nombre,
            value: element.id
          })
        })

        return listForForms
      }

      return []
    }
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    }
  },

  actions: {
    async getAll ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await resource.all(
        { periodo_fiscal_id },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setList', data.data)

      return { data, message }
    }
  }
}
