import Resource from '@/api/Resource'
import service from '@/api/service'

const resource = new Resource('/api/saldos', 'saldoModule')

export default {
  namespaced: true,

  state: {
    reporte: null,

    params: {
      periodo_fiscal_id: null,
      fuente_financiamiento_id: null,
      proyecto_id: null,
      unidad_administrativa_id: null,
      tipo_gasto_id: null,
      fecha_corte: null,
      presupuesto: null
    },

    isGettingResource: false
  },

  getters: {
    hasReporte: state => {
      return Boolean(state.reporte)
    },

    getReporte: ({ reporte }, getters) => {
      return getters.hasReporte ? reporte.data : []
    },

    getParams: state => {
      return state.params
    }
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setReporte (state, reporte) {
      state.reporte = reporte
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setCurrentClave (state, payload) {
      state.currentClave = payload
    },
    setCurrentPage (state, payload) {
      state.currentPage = payload
    },

    setPeriodoFiscalId (state, payload) {
      state.params.periodo_fiscal_id = payload
    },
    setFechaCorte (state, payload) {
      state.params.fecha_corte = payload
    },
    setFuenteFinanciamientoId (state, payload) {
      state.params.fuente_financiamiento_id = payload
    },
    setProyectoId (state, payload) {
      state.params.proyecto_id = payload
    },
    setUnidadAdministrativaId (state, payload) {
      state.params.unidad_administrativa_id = payload
    },
    setTipoGastoId (state, payload) {
      state.params.tipo_gasto_id = payload
    },
    setPresupuesto (state, payload) {
      state.params.presupuesto = payload
    }
  },

  actions: {
    async loadReporte ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await resource.get('api/reportes/saldos-partidas', {
          periodo_fiscal_id: payload.periodo_fiscal_id,
          fuente_financiamiento_id: payload.fuente_financiamiento_id,
          proyecto_id: payload.proyecto_id,
          unidad_administrativa_id: payload.unidad_administrativa_id,
          tipo_gasto_id: payload.tipo_gasto_id,
          fecha_corte: payload.fecha_corte,
          presupuesto: payload.presupuesto
        }, 'setIsGettingResource')

        retval = data

        commit('setReporte', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportReporte ({ context }, payload) {
      let retval = {}

      try {
        // Duplicamos la llamada para verificar primero si no hay un
        // error y mostrar el mensaje correcto al usuario.
        // Hacemos la petición get sin especificar que se recibe un blob
        await service({
          _method: 'GET',
          url: 'api/reportes/saldos-partidas/export',
          params: {
            periodo_fiscal_id: payload.periodo_fiscal_id,
            fuente_financiamiento_id: payload.fuente_financiamiento_id,
            proyecto_id: payload.proyecto_id,
            unidad_administrativa_id: payload.unidad_administrativa_id,
            tipo_gasto_id: payload.tipo_gasto_id,
            fecha_corte: payload.fecha_corte,
            presupuesto: payload.presupuesto
          }
        })
        // Si no existe error la excepción no se lanza y podemos
        // obtener el blob correctamente
        const { data } = await service({
          _method: 'GET',
          url: 'api/reportes/saldos-partidas/export',
          responseType: 'blob',
          params: {
            periodo_fiscal_id: payload.periodo_fiscal_id,
            fuente_financiamiento_id: payload.fuente_financiamiento_id,
            proyecto_id: payload.proyecto_id,
            unidad_administrativa_id: payload.unidad_administrativa_id,
            tipo_gasto_id: payload.tipo_gasto_id,
            fecha_corte: payload.fecha_corte,
            presupuesto: payload.presupuesto
          }
        })
        retval = data
      } catch (error) {
        retval = { error: true, message: error.message, ...error.response.data }
      }

      return retval
    }
  }
}
