<template>
  <label :for="context.id" class="text-black small font-weight-bolder">
    {{ context.label }}
    <span v-if="required" class="text-danger">*</span>
  </label>
</template>

<script>
export default {
  name: 'XLabel',

  props: {
    context: {
      type: Object,
      required: true
    },

    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>
