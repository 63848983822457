<template>
  <check-authorization
    :requiresAuthorizations="requiresAuthorizations"
    :show-alert="false"
    no-slots
    #default="{ authorized, message }"
  >
    <span v-b-tooltip.top :title="authorized ? '' : message">
      <b-button
        :variant="variant"
        size="sm"
        :disabled="!authorized"
        @click="$isAuthorized(authorized, callback)"
        :class="btnClass"
      >
        <slot></slot>
      </b-button>
    </span>
  </check-authorization>
</template>

<script>
export default {
  name: 'XBtnWithAuth',

  props: {
    requiresAuthorizations: {
      type: Array,
      required: true,
      default: () => []
    },

    variant: {
      type: String,
      required: false,
      default: 'light'
    },

    callback: {
      type: Function,
      required: false,
      default: () => ({})
    },

    btnClass: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
