import axios from 'axios'
import store from '@/store'
import router from '@/router'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_AXIOS_PROD_BASE_URL
    : process.env.VUE_APP_AXIOS_LOCAL_BASE_URL

const service = axios.create({
  baseURL,
  withCredentials: true
})

const closeSession = () => {
  store.commit('userModule/resetState')
  router.push('/login')
  window.location.reload()
}

const _debug = error => {
  console.error('')
  console.group('Debug error response')
  console.log(`MESSAGE: ${error.message}`)
  console.log(`BASE URL: ${error.config.baseURL}`)
  console.log(`URL: ${error.config.url}`)
  console.log(`METHOD: ${error.config.method}`)
  console.log(`PAYLOAD: ${error.config.data}`)
  console.error(`RESPONSE DATA: ${JSON.stringify(error.response.data.data, null, 4)}`)
  console.log(`RESPONSE MESSAGE: ${error.response.data.message}`)
  console.groupEnd()
  console.error('')
}

const _debugResponse = res => {
  console.log('')
  console.group('Debug service response')
  console.log(`MESSAGE: ${res.statusText}`)
  console.log(`STATUS: ${res.status}`)
  console.log(`BASE URL: ${res.config.baseURL}`)
  console.log(`URL: ${res.config.url}`)
  console.log(`METHOD: ${res.config.method}`)
  console.log(`PARAMS: ${JSON.stringify(res.config.params, null, 4)}`)
  console.log(`RESPONSE DATA: ${JSON.stringify(res.data, null, 4)}`)
  console.groupEnd()
  console.log('')
}

service.interceptors.response.use(
  response => {
    if (process.env.VUE_APP_LOCAL_DEBUG_SERVICE_RESPONSE === 'true') _debugResponse(response)

    return Promise.resolve(response)
  },

  error => {
    if (process.env.VUE_APP_LOCAL_DEBUG_SERVICE_RESPONSE === 'true') _debug(error)

    if (error.response && error.response.status === 419) {
      closeSession()
    }

    if (
      error.response &&
      error.response.status === 401 &&
      store.getters['authModule/isAuthenticated']
    ) {
      closeSession()
    }

    //
    // De repente cuando el sistema se deja un tiempo sin usar,
    // y se vuelve a tratar de iniciar sesión, el backend ya ha terminado la sesión,
    // el frontend no lo sabe hasta que se hace la petición y ésta falla,
    // y cuando se falla ocurre de vez en cuando un error el cual es el CSRF Token
    // que ha se ha vencido.
    //
    // La forma más simple de solucionar es volver a recargar la página para solicitar
    // un nuevo CSRF Token al backend.
    //
    // NOTA: Error como tal no he conseguido, es eso que lo condiciono con el mensaje de error.
    //
    if (error.response.data.message === 'CSRF token mismatch.') closeSession()

    return Promise.reject(error)
  }
)

export default service
