import service from '@/api/service'
import Resource from '@/api/Resource'

const administrativeUnits = new Resource('/api/administrative-units', 'administrativeUnitModule')

export default {
  namespaced: true,

  state: {
    /**
     * Representa a todas las unidades administrativas en el sistema
     */
    administrativeUnits: null,

    /**
     * Cuando se obtiene la informacion de una unidad administrativa a actualizar,
     * se almacena en esta variable, declarada como nula de forma inicial,
     * para saber si se está actualmente editando o no una unidad
     */
    editingAdministrativeUnit: null,
    /**
     * Término ingresado para buscar unidad administrativa
     */
    query: null
  },

  getters: {
    hasAdministrativeUnits: state => Boolean(state.administrativeUnits),

    getAdministrativeUnits: (state, getters) =>
      getters.hasAdministrativeUnits ? state.administrativeUnits : [],

    isEditingAdministrativeUnit: state => Boolean(state.editingAdministrativeUnit),

    getAdministrativeUnitsForForms: (state, getters) => {
      if (getters.hasAdministrativeUnits) {
        const administrativeUnitsForForms = []

        getters.getAdministrativeUnits.forEach(administrativeUnit => {
          administrativeUnitsForForms.push({
            label: administrativeUnit.nombre,
            value: administrativeUnit.id
          })
        })

        return administrativeUnitsForForms
      }

      return []
    },
    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setAdministrativeUnits (state, administrativeUnits) {
      state.administrativeUnits = administrativeUnits
    },

    setAdministrativeUnitToEdit (state, administrativeUnit) {
      state.editingAdministrativeUnit = administrativeUnit
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setQuery (state, payload) {
      state.query = payload
    }
  },

  actions: {
    async getAdministrativeUnits ({ state, getters, commit, dispatch }, periodo_fiscal_id, page = null) {
      if (getters.isSearchingMode) return dispatch('search', { search: state.query, page })

      // const { error, data, message } = await administrativeUnits.all({ periodo_fiscal_id })

      const { error, message, data } =
        page !== null
          ? await administrativeUnits.get(`api/administrative-units?page=${page}`, null, 'setIsGettingResource')
          : await administrativeUnits.all({ periodo_fiscal_id }, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setAdministrativeUnits', data.data)

      return { data, message }
    },

    async search ({ commit }, { search, page }) {
      const { error, message, data } = await administrativeUnits.get(
        'api/administrative-units/search',
        { search, page },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setAdministrativeUnits', data.data.data)

      return data
    },

    async getUnidadesAdministrativasList ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await service.get('api/administrative-units?periodo_fiscal_id=' + periodo_fiscal_id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async createAdministrativeUnit ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.create(payload)

        retval = data

        dispatch('getAdministrativeUnits')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getAdministrativeUnitToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.show(id)

        retval = data

        commit('setAdministrativeUnitToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateAdministrativeUnit ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.update(id, payload)

        retval = data

        commit('setAdministrativeUnitToEdit', null)

        dispatch('getAdministrativeUnits')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteAdministrativeUnit ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.delete(id)

        retval = data

        dispatch('getAdministrativeUnits')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async export ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `api/administrative-units/export?periodo_id=${payload.periodo_id}&formato=${payload.formato}`,
          responseType: 'blob',
          params: {
            periodo_id: payload.periodo_id,
            formato: payload.formato
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
