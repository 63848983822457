<template>
  <div class="card">
    <div class="card-header border-bottom">
      <!-- Wizard navigation-->
      <div
        class="nav nav-pills nav-justified flex-column justify-around flex-md-row nav-wizard"
        role="tablist"
      >
        <slot name="navigation"></slot>
      </div>
    </div>

    <div class="card-body">
      <div class="tab-content" id="cardTabContent">
        <!-- @DEFAULT -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XCardWizard'
}
</script>
