import Resource from '@/api/Resource'
import service from '@/api/service'
import _ from 'lodash'

const fiscalPeriods = new Resource('/api/fiscal-periods', 'fiscalPeriodsModule')

export default {
  namespaced: true,

  state: {
    fiscalPeriods: [],

    activeFiscalPeriod: null,

    currentFiscalPeriod: null,

    currentFiscalPeriodForCopy: null,

    isCreatingResource: false,

    isGettingResource: true,

    isDeletingResource: false
  },

  getters: {
    getActiveFiscalPeriod: state => state.activeFiscalPeriod,

    getAllFiscalPeriods: state => {
      return state.fiscalPeriods.length ? state.fiscalPeriods : []
    },

    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.setIsDeletingResource
    },

    isEditingResource: state => Boolean(state.currentFiscalPeriod),

    isCopyingResource: state => Boolean(state.currentFiscalPeriodForCopy),

    getCurrentEditing: state => {
      return state.currentFiscalPeriod
    },

    getCurrentEditingForCopy: state => {
      return state.currentFiscalPeriodForCopy
    },

    getLastFiscalPeriod: (state, getters) =>
      _.orderBy(getters.getAllFiscalPeriods, ['nombre'], ['desc'])[0],

    getFiscalPeriodsForForms: (state, getters) => {
      if (getters.getAvailableList) {
        const fiscalPeriodsForForms = []

        getters.getAllFiscalPeriods.forEach(fiscalPeriod => {
          fiscalPeriodsForForms.push({
            label: fiscalPeriod.nombre,
            value: fiscalPeriod.id
          })
        })

        return fiscalPeriodsForForms
      }

      return []
    }
  },

  mutations: {
    setActiveFiscalPeriod (state, payload) {
      state.activeFiscalPeriod = payload
    },

    setFiscalPeriods (state, payload) {
      state.fiscalPeriods = payload
    },

    setCurrentFiscalPeriod (state, payload) {
      state.currentFiscalPeriod = payload
    },

    setCurrentFiscalPeriodForCopy (state, payload) {
      state.currentFiscalPeriodForCopy = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    /**
     * Obtiene el listado de periodos fiscales.
     *
     * Ejecuta la acción para recuperar todos los periodos fiscales,
     * cuando la respuesta es satisfactoria, ejecuta una mutación
     * para almacenarlos localmente.
     *
     * @return {boolean}  error    Variable que indica si ha ocurrido un error.
     * @return {string}   message  Variable que indica el resultado del proceso.
     * @return {Object}   data     Variable que contiene o no, información adicional.
     */
    async getFiscalPeriods ({ commit }) {
      const { error, message, data } = await fiscalPeriods.all({}, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setFiscalPeriods', data.data)

      return { data, message }
    },

    async getPreviousFiscalPeriods ({ commit }, payload) {
      const { error, message, data } = await service.get('api/fiscal-periods/previos?periodo_id=' + payload.id)

      if (error) return { error, message }

      return { data, message }
    },

    async getAllFiscalPeriods ({ commit }) {
      const { error, message, data } = await service.get('api/fiscal-periods')

      if (error) return { error, message }

      return { data, message }
    },

    async getForControlPanel ({ commit }) {
      const { error, message, data } = await service.get('api/fiscal-periods/panel')

      if (error) return { error, message }

      commit('setFiscalPeriods', data.data)

      return { data: data.data, message }
    },

    /**
     * Crea un periodo fiscal.
     *
     * Crea un nuevo periodo fiscal, si la respuesta ha sido satisfactoria
     * ejecuta una acción para obtener todos los permisos.
     *
     * @param {Object} payload.name           Nombre del periodo fiscal.
     * @param {Object} payload.description    Nombre del periodo fiscal.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async createFiscalPeriod ({ dispatch }, payload) {
      let retval = {}
      console.log('create')
      console.log(payload)

      try {
        const { data } = await fiscalPeriods.create(payload, 'setIsCreatingResource')
        retval = data
        dispatch('getFiscalPeriods')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Obtiene un recurso en específico.
     *
     * @param {int} id Id del recurso a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getFiscalPeriod ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await fiscalPeriods.show(id)
        retval = data
        commit('setCurrentFiscalPeriod', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getFiscalPeriodForCopy ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await fiscalPeriods.show(id)
        retval = data
        commit('setCurrentFiscalPeriodForCopy', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     *  Actualiza un periodo fiscal.
     *
     * @param {int}     id                    Id del recurso a actualizar.
     * @param {Object}  payload.name          Nombre del periodo fiscal a actualizar.
     * @param {Object}  payload.description   Descripción del periodo fiscal a actualizar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateFiscalPeriod ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await fiscalPeriods.update(id, payload)
        retval = data
        commit('setCurrentFiscalPeriod', null)
        await dispatch('getFiscalPeriods')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateCopyFiscalPeriod ({ commit, dispatch }, payload) {
      let retval = {}
      console.log('updateCopy')
      console.log(payload)

      try {
        const { data } = await service.post('api/fiscal-periods/duplicar', payload)
        retval = data
        commit('setCurrentFiscalPeriod', null)
        await dispatch('getFiscalPeriods')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Eliminar un periodo fiscal dado su id.
     *
     * @param {int} id Id del periodo fiscal a eliminar
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteFiscalPeriod ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await fiscalPeriods.delete(id, 'setIsDeletingResource')
        retval = data
        await dispatch('getFiscalPeriods')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Consulta el periodo fiscal activo.
     *
     * @return {Number}   retval.id: 1
     * @return {Number}   retval.estatus: 1
     * @return {String}   retval.nombre: "2021"
     * @return {String}   retval.techo_financiero: "0.0000"
     * @return {String}   retval.descripcion: "Períodos Fiscal Año 2021"
     * @return {Datetime} retval.created_at: "2021-07-15T20:27:59.000000Z"
     * @return {Datetime} retval.updated_at: "2021-07-15T20:27:59.000000Z"
     */
    async getActiveFiscalPeriod ({ commit }) {
      let retval = {}

      try {
        const { data } = await service.get('api/fiscal-periods/active')

        retval = data.data

        commit('setActiveFiscalPeriod', retval)
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getActiveFiscalPeriodByModule ({ commit }, module) {
      let retval = {}

      try {
        const { data } = await service.get(`api/fiscal-periods/modulo/${module}`)
        retval = data.data

        commit('setActiveFiscalPeriod', retval)
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }
      return retval
    }
  }
}
