import Resource from '@/api/Resource'

const roles = new Resource('/api/roles', 'Roles')

export default {
  namespaced: true,

  state: {
    /**
     * Representa a todos los roles disponibles en el sistema.
     */
    systemRoles: [],

    currentRole: null
  },

  getters: {
    isEditingRole: state => Boolean(state.currentRole),

    getSystemRoles: state => state.systemRoles || [],

    getSystemRolesNames: state =>
      state.systemRoles.length > 0 ? state.systemRoles.map(r => r.name) : []
  },

  mutations: {
    setSystemRoles (state, payload) {
      state.systemRoles = payload
    },

    setCurrentRole (state, payload) {
      state.currentRole = payload
    }
  },

  actions: {
    async getSystemRoles ({ commit }) {
      let retval = {}

      try {
        const { data } = await roles.get('/api/all-roles-checklist')
        retval = data
        commit('setSystemRoles', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async createRole ({ commit, dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await roles.create(payload)
        retval = data
        await dispatch('getSystemRoles')
        commit('permissionModule/setCurrentRolePermissions', [], { root: true })
        dispatch('permissionModule/getPermissions', null, { root: true })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getRole ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await roles.show(id)
        retval = { ...data }
        commit('setCurrentRole', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateRole ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await roles.update(id, payload)
        retval = data
        commit('setCurrentRole', null)
        commit('permissionModule/setCurrentRolePermissions', [], { root: true })
        await dispatch('getSystemRoles')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina un rol en específico.
     *
     * Elimina un rol por su id, si la respuesta satisfactoria
     * entonces trae todos los roles para guardarlos en local.
     *
     * @param {int} id Id del rol a eliminar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteRole ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await roles.delete(id)
        retval = data
        await dispatch('getSystemRoles')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
