import Resource from '@/api/Resource'

const saldos = new Resource('/api/saldos', 'saldoModule')

export default {
  namespaced: true,

  state: {
    saldos: [],

    isGettingResource: false
  },

  getters: {
    hasSaldos: state => {
      return state.saldos.data
    },

    getSaldos: ({ saldos }, getters) => {
      return getters.hasSaldos ? saldos.data : []
    }
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setSaldos (state, saldos) {
      state.saldos = saldos
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    }
  },

  actions: {
    async getSaldosUnidadesAdministrativas ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await saldos.get('api/saldos/unidades-administrativas', {
          periodo_fiscal_id: periodo_fiscal_id
        }, 'setIsGettingResource')

        retval = data

        commit('setSaldos', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getSaldosPorUnidad ({ commit }, { periodo_fiscal_id, unidad_id }) {
      let retval = {}

      try {
        const { data } = await saldos.get(`api/saldos/unidades-administrativas/${unidad_id}`, {
          periodo_fiscal_id: periodo_fiscal_id
        }, 'setIsGettingResource')

        retval = data

        commit('setSaldos', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getSaldosPartidasPresupuestales ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await saldos.get('api/saldos/partidas-presupuestales', {
          periodo_fiscal_id: periodo_fiscal_id
        }, 'setIsGettingResource')

        retval = data

        commit('setSaldos', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getSaldosPorPartida ({ commit }, { periodo_fiscal_id, partida_id }) {
      let retval = {}

      try {
        const { data } = await saldos.get(`api/saldos/partidas-presupuestales/${partida_id}`, {
          periodo_fiscal_id: periodo_fiscal_id
        }, 'setIsGettingResource')

        retval = data

        commit('setSaldos', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getSaldosMomentosContables ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await saldos.get('api/saldos/momentos-contables', {
          periodo_fiscal_id: payload.periodo_fiscal_id,
          momento: payload.momento,
          mes: payload.mes
        }, 'setIsGettingResource')

        retval = data

        commit('setSaldos', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
