import service from '@/api/service'

const ENDPOINT = 'api/traspasos'
const IMPORTAR_PARTIDA = `${ENDPOINT}/import`
const IMPORTAR_PROPUESTAS = `${ENDPOINT}/import`

const importTraspaso = () => ({
  files: [],
  periodo_fiscal_id: null,
  result: []
})

export default {
  namespaced: true,

  state: {
    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isLoadingResource: false,

    importTraspaso: importTraspaso(),

    traspasosList: [],

    currentTraspaso: null,

    currentMovimiento: null,

    waitToEditItem: false
  },

  getters: {
    getImportTraspaso (state) {
      return state.importTraspaso
    },

    isCreatingResource (state) {
      return state.isCreatingResource
    },

    getTraspasoByMonth: state => mes => {
      return state.traspasosList.filter(el => el.mes === mes)
    },

    isEditingTraspaso (state) {
      return Boolean(state.currentTraspaso)
    },

    isWatingForEditItem (state) {
      return Boolean(state.waitToEditItem)
    },

    getMovimientosOfCurrentTraspaso (state) {
      return Boolean(state.currentTraspaso) && state.currentTraspaso.traspasos
        ? state.currentTraspaso.traspasos
        : []
    }
  },

  mutations: {
    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setIsLoadingResouce (state, payload) {
      state.isLoadingResource = payload
    },

    setTraspasosList (state, payload) {
      state.traspasosList = payload
    },

    setImporteTraspaso (state, payload) {
      const idx = state.traspasosList.findIndex(el => el.id === payload.id)
      state.traspasosList[idx].total = payload.importe
      state.traspasosList[idx].ampliaciones = payload.ampliaciones
      state.traspasosList[idx].reducciones = payload.reducciones
    },

    setCurrentTraspaso (state, payload) {
      state.currentTraspaso = payload
    },

    setCurrentMovimiento (state, payload) {
      state.currentMovimiento = payload
    },

    setWaitToEditItem (state, payload) {
      state.waitToEditItem = payload
    }
  },

  actions: {
    async crear ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await service.post(ENDPOINT, payload)

        retval = data

        dispatch('listar', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async crearMovimiento ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await service.post(`${ENDPOINT}/movimientos`, payload)

        retval = data

        dispatch('listar', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async listar ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(ENDPOINT, { params })

        retval = data

        commit('setTraspasosList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getTraspasoById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/${id}`)

        retval = data

        commit('setCurrentTraspaso', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/${id}`)

        retval = data

        commit('setCurrentTraspaso', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getMovimientosTraspasoById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/movimientos?traspaso_id=${id}`)

        return data.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
        return retval
      }
    },

    async getMovimientoTraspasoById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/movimientos/${id}`)

        retval = data

        return data.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
        return retval
      }
    },

    async getMovimientoById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/movimientos/${id}`)

        retval = data

        commit('setCurrentMovimiento', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    },

    async updateById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        payload._method = 'PUT'
        const { data } = await service.put(`${ENDPOINT}/${id}`, payload)

        retval = data

        commit('setCurrentTraspaso', null)
        dispatch('listar', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateMovimientoById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        payload._method = 'PUT'
        const { data } = await service.put(`${ENDPOINT}/movimientos/${id}`, payload)

        retval = data

        commit('setCurrentMovimiento', null)
        /* dispatch('listarSuficiencias', {
          periodo_fiscal_id: payload.periodo_fiscal_id
        }) */
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteMovimientoById ({ dispatch }, { id, data }) {
      let retval = {}

      try {
        const getval = await service.delete(`${ENDPOINT}/movimientos/${id}`, { data })

        retval = getval.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteById ({ dispatch }, { id, data }) {
      let retval = {}

      try {
        const getval = await service.delete(`${ENDPOINT}/${id}`, { data })

        retval = getval.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async import ({ dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('traspaso_id', payload.traspaso_id)
        formData.append('periodo_fiscal_id', payload.periodo_fiscal_id)
        formData.append('save_file', payload.save_file ? 1 : 0)
        formData.append('overwrite', payload.overwrite ? 1 : 0)

        const { data } = await service.post(
          payload.partida_propuestas === 'partida' ? IMPORTAR_PARTIDA : IMPORTAR_PROPUESTAS,
          formData
        )

        retval = data

        dispatch('listar', payload)
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    },

    async exportTraspasos ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/export`,
          responseType: 'blob',
          params: {
            id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async enviarTraspaso ({ dispatch }, { traspaso_id }) {
      let retval = {}

      try {
        const { data } = await service.post(`${ENDPOINT}/enviar`, {
          traspaso_id: traspaso_id
        })

        retval = data

        dispatch('listar')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async autorizarTraspaso ({ dispatch }, { traspaso_id, folio, fecha, ccp, text }) {
      let retval = {}

      try {
        const { data } = await service.post(`${ENDPOINT}/autorizar`, {
          traspaso_id: traspaso_id,
          oficio: folio,
          fecha: fecha,
          ccp: ccp,
          text: text
        })

        retval = data

        dispatch('listar')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async rechazarTraspaso ({ dispatch }, { traspaso_id, texto_rechazo }) {
      let retval = {}

      try {
        const { data } = await service.post(`${ENDPOINT}/rechazar`, {
          traspaso_id: traspaso_id,
          concepto_rechazo: texto_rechazo
        })

        retval = data

        dispatch('listar')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async downloadAcuse ({ context }, traspaso_id) {
      let retval = {}

      try {
        // Duplicamos la llamada para verificar primero si no hay un
        // error y mostrar el mensaje correcto al usuario.
        // Hacemos la petición get sin especificar que se recibe un blob
        await service({
          _method: 'GET',
          url: `${ENDPOINT}/acuse`,
          params: {
            traspaso_id
          }
        })
        // Si no existe error la excepción no se lanza y podemos
        // obtener el blob correctamente
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/acuse`,
          responseType: 'blob',
          params: {
            traspaso_id
          }
        })
        retval = data
      } catch (error) {
        retval = { error: true, message: error.message, ...error.response.data }
      }

      return retval
    },

    async setEnviado ({ context }, id) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('traspaso_id', id)
        formData.append('enviado', 1)
        // eslint-disable-next-line no-undef
        const { data } = await service.post(`${ENDPOINT}/enviado`, formData)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
