import service from '@/api/service'
import Resource from '@/api/Resource'
import _ from 'lodash'

const tipoGasto = new Resource(
  '/api/tipo-gasto',
  'tipoGastoModule'
)

export default {
  namespaced: true,

  state: {
    list: [],

    //
    // Tipo de Gasto actual, seleccionada desde el backend.
    currentTipoGasto: null,

    currentResource: null,

    isCretingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    getList: state => state.list,

    isListAvailable: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource && state.list.length > 0
    },

    isEditingResource: state => Boolean(state.currentResource),

    getCurrentEditing: state => state.currentResource,

    /**
     * @returns {Object} Obtiene el tipo de gasto seleccionado por defecto,
     *                   esta se consulta desde el backend.
     */
    getCurrentTipoGasto: state => state.currentTipoGasto
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setCurrentResource (state, payload) {
      state.currentResource = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setCurrentTipoGasto (state, payload) {
      state.currentTipoGasto = payload
    }
  },

  actions: {
    /**
     * Obtiene todas los tipos de gasto
     */
    async getAll ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await tipoGasto.all(
        {
          periodo_fiscal_id
        },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setList', data.data)

      return { data: data.data, message }
    },

    async getTiposGastoList ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await service.get('api/tipo-gasto?periodo_fiscal_id=' + periodo_fiscal_id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Crea un recurso.
     *
     * @param {Object} payload.name           Nombre del recurso.
     * @param {Object} payload.description    Nombre del recurso.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async create ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await tipoGasto.create(payload, 'setIsCreatingResource')
        retval = data
        dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Obtiene un recurso en específico.
     *
     * @param {int} id Id del recurso a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await tipoGasto.show(id)
        retval = data
        commit('setCurrentResource', data.data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async getToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await tipoGasto.show(id)

        retval = data

        commit('setCurrentResource', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     *  Actualiza un recurso.
     *
     * @param {int}     id                    Id del recurso a actualizar.
     * @param {Object}  payload.name          Nombre del recurso a actualizar.
     * @param {Object}  payload.description   Descripción del recurso a actualizar.
     *
     * @return {boolean}  retval.error   Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message Variable que indica el resultado del proceso.
     * @return {Object}   retval.data    Variable que contiene o no, información adicional.
     */
    async updateById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await tipoGasto.update(id, payload)
        retval = data
        commit('setCurrentResource', null)
        await dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Eliminar un recurso dado su id.
     *
     * @param {int} id Id del recurso a eliminar
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteById (_, id) {
      let retval = {}

      try {
        const { data } = await tipoGasto.delete(id, 'setIsDeletingResource')
        retval = data
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },
    async getByName ({ dispatch }, { key, value, periodo_fiscal_id }) {
      const { error, data, message } = await dispatch('getAll', periodo_fiscal_id)

      if (error) return { error, message }

      const retval = _.find(data, { [key]: value })

      return { data: retval, message }
    }
  }
}
