<template>
  <b-alert :show="error" variant="danger">
    <h5>{{ title }}</h5>

    <p>{{ message }}</p>

    <ul v-if="errors">
      <li v-for="error in Object.values(errors)" :key="error.id">
        {{ error }}
      </li>
    </ul>
  </b-alert>
</template>

<script>
export default {
  name: 'AlertWithErrors',

  props: {
    error: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    message: {
      type: String,
      required: false,
      default: ''
    },

    errors: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>
