var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-auth-view',[_c('FormulateForm',{on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var isValid = ref.isValid;
return [_c('h1',{staticClass:"text-center"},[_vm._v("Iniciar sesión")]),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"name":"email","label":"Email","placeholder":"Ingresa la dirección de correo","validation":"bail|required|email","validation-messages":{
        required: 'El email es requerido',
        email: 'Ingresa un email válido'
      }},model:{value:(_vm.formValues.email),callback:function ($$v) {_vm.$set(_vm.formValues, "email", $$v)},expression:"formValues.email"}}),_c('FormulateInput',{attrs:{"name":"password","label":"Contraseña","type":"password","placeholder":"Ingresa tu contraseña","validation":"bail|required","validation-messages":{
        required: 'La contraseña es requerida'
      }},model:{value:(_vm.formValues.password),callback:function ($$v) {_vm.$set(_vm.formValues, "password", $$v)},expression:"formValues.password"}}),_c('FormulateInput',{attrs:{"type":"submit","input-class":"btn btn-primary btn-block","disabled":isLoading || !isValid,"label":isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}}),_c('div',{staticClass:"my-2 text-right"},[_c('router-link',{staticClass:"small",attrs:{"to":{ name: 'RecoverPassword' }}},[_vm._v(" Recuperar contraseña ")])],1),_c('hr'),_c('button',{staticClass:"mt-1 btn btn-google btn-block",attrs:{"disabled":_vm.isGoogleAuthenticating,"type":"button"},on:{"click":function($event){return _vm.AuthProvider('google')}}},[_c('i',{staticClass:"fab fa-google"}),_vm._v(" "+_vm._s(_vm.isGoogleAuthenticating ? 'Espere...' : 'Acceder con Google')+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }