import Resource from '@/api/Resource'
import _ from 'lodash'
import service from '../../api/service'

const prestaciones = new Resource('api/prestaciones', 'prestacionesModule')

export default {
  namespaced: true,

  state: {
    list: [],

    currentResource: null,

    isCreatingResource: false,

    isUpdatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    getList: state => _.orderBy(state.list, ['nombre']) || [],

    isListAvailable: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.setIsDeletingResource
    },

    isEditingResource: state => Boolean(state.currentResource),

    getCurrentEditing: state => state.currentResource
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setCurrentResource (state, payload) {
      state.currentResource = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsUpdatingResource (state, payload) {
      state.isUpdatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    updatePriority (state, payload) {
      state.list = payload
    }
  },

  actions: {
    /**
     * Obtiene todas las prestaciones.
     */
    async getAll ({ commit }, periodo_fiscal_id) {
      const payload = {
        periodo_fiscal_id
      }

      const { error, data, message } = await prestaciones.all(payload, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setList', data.data)

      return { data, message }
    },

    /**
     * Crea una prestación
     */
    async create ({ commit, dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await prestaciones.create(payload, 'setIsCreatingResource')
        retval = data
        dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      } finally {
        commit('setIsCreatingResource', false)
      }

      return retval
    },

    /**
     * Obtiene un recurso por su id
     */
    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await prestaciones.show(id, 'setIsGettingResource')
        retval = data
        commit('setCurrentResource', data.data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async getItemById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get('api/prestaciones/' + id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     *  Actualiza un recurso por su id.
     *
     * @param {int}     id                    Id del recurso a actualizar.
     * @param {Object}  payload.name          Nombre del recurso a actualizar.
     * @param {Object}  payload.description   Descripción del recurso a actualizar.
     *
     * @return {boolean}  error    Variable que indica si ha ocurrido un error.
     * @return {string}   message  Variable que indica el resultado del proceso.
     * @return {Object}   data     Variable que contiene o no, información adicional.
     */
    async updateById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await prestaciones.update(id, payload, 'setIsUpdatingResource')
        retval = data
        commit('setCurrentResource', null)
        await dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async updatePriority ({ commit }, payload) {
      commit('updatePriority', payload)
      const { data } = await service.post('api/prestaciones-prioridad', payload)
      commit('setIsCreatingResource', false)

      return { error: data.error, message: data.message }
    },

    /**
     * Eliminar un recurso dado su id.
     *
     * @param {int} id Id del recurso a eliminar
     *
     * @return {boolean}  error    Variable que indica si ha ocurrido un error.
     * @return {string}   message  Variable que indica el resultado del proceso.
     * @return {Object}   data     Variable que contiene o no, información adicional.
     */
    async deleteById ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await prestaciones.delete(id, 'setIsDeletingResource')
        retval = data
        await dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    }
  }
}
