import service from '@/api/service'

const ENDPOINT = 'api/export-saacgnet'

const importTraspaso = () => ({
  files: [],
  periodo_fiscal_id: null,
  result: []
})

export default {
  namespaced: true,

  state: {
    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isLoadingResource: false,

    importTraspaso: importTraspaso(),

    traspasosList: [],

    currentTraspaso: null,

    waitToEditItem: false
  },

  getters: {
    getImportTraspaso (state) {
      return state.importTraspaso
    },

    isCreatingResource (state) {
      return state.isCreatingResource
    },

    getTraspasoByMonth: state => mes => {
      return state.traspasosList.filter(el => el.mes === mes)
    },

    isEditingTraspaso (state) {
      return Boolean(state.currentTraspaso)
    },

    isWatingForEditItem (state) {
      return Boolean(state.waitToEditItem)
    },

    getMovimientosOfCurrentTraspaso (state) {
      return Boolean(state.currentTraspaso) && state.currentTraspaso.movimientos
        ? state.currentTraspaso.movimientos
        : []
    }
  },

  mutations: {
    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setIsLoadingResouce (state, payload) {
      state.isLoadingResource = payload
    },

    setTraspasosList (state, payload) {
      state.traspasosList = payload
    },

    setCurrentTraspaso (state, payload) {
      state.currentTraspaso = payload
    },

    setWaitToEditItem (state, payload) {
      state.waitToEditItem = payload
    }
  },

  actions: {
    async crear ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await service.post(ENDPOINT, payload)

        retval = data

        dispatch('listar', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async listar_estatal_federal ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/estatal-federal`, { params })

        retval = data

        commit('setTraspasosList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async export_estatal_federal ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/estatal-federal`,
          responseType: 'blob',
          params: {
            id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async listar_ampliaciones_reducciones ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/ampliaciones-reducciones`, { params })

        retval = data

        commit('setTraspasosList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async export_ampliaciones_reducciones ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/ampliaciones-reducciones`,
          responseType: 'blob',
          params: {
            id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async listar_traspasos ({ commit }, params) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/traspasos`, { params })

        retval = data

        commit('setTraspasosList', data.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async export_traspasos ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/traspasos`,
          responseType: 'blob',
          params: {
            id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await service.get(`${ENDPOINT}/${id}`)

        retval = data

        commit('setCurrentTraspaso', data.data)
        commit('setWaitToEditItem', false)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        payload._method = 'PUT'
        const { data } = await service.put(`${ENDPOINT}/${id}`, payload)

        retval = data

        commit('setCurrentTraspaso', null)
        dispatch('listar', payload)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteById ({ dispatch }, { id, data }) {
      let retval = {}

      try {
        const getval = await service.delete(`${ENDPOINT}/${id}`, { data })

        retval = getval.data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async export ({ context }, id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/export`,
          responseType: 'blob',
          params: {
            id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async autorizarTraspaso ({ dispatch }, { traspaso_id, confirmation, autorizado, folio, fecha, ccp, text }) {
      let retval = {}

      try {
        const { data } = await service.post(`${ENDPOINT}/${autorizado ? 'autorizar' : 'rechazar'}`, {
          traspaso_id: traspaso_id,
          confirmation: confirmation,
          oficio: folio,
          fecha: fecha,
          ccp: ccp,
          text: text
        })

        retval = data

        dispatch('listar')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async downloadAcuse ({ context }, traspaso_id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${ENDPOINT}/acuse`,
          responseType: 'blob',
          params: {
            traspaso_id
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async setEnviado ({ context }, id) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('traspaso_id', id)
        formData.append('enviado', 1)
        // eslint-disable-next-line no-undef
        const { data } = await service.post(`${ENDPOINT}/enviado`, formData)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
