import Vue from 'vue'
import service from '@/api/service'
import Resource from '@/api/Resource'

const IMPORTAR_LAYOUT = 'api/file-import-ampliaciones-reducciones'

const API_AMPLIACIONES_REDUCCIONES = new Resource('/api/ampliacion-reduccion', 'ampliacionReduccionModule')

export default {
  namespaced: true,

  state: {
    fuente_financiamiento: null,

    periodo_fiscal: null,

    selected: null,

    registros: null,

    importacion: {
      file: null,
      overwrite: false,
      save_file: false,
      loading: false,
      processed: false
    },

    recursos: {
      response: [],
      loading: false,
      processed: false
    }
  },

  getters: {
    hasPeriodoFiscal (state) {
      return Boolean(state.periodo_fiscal)
    },

    hasFuenteFinanciamiento (state) {
      return Boolean(state.fuente_financiamiento)
    },

    isImportacionFormValid (state) {
      return [
        // Valores por defecto
        Boolean(state.fuente_financiamiento),
        //
        // Referente a la vista
        Boolean(state.importacion.file)
      ].every(item => item)
    }
  },

  mutations: {
    setImportacion (state, { key, value }) {
      Vue.set(state.importacion, key, value)
    },

    setRecursos (state, { key, value }) {
      Vue.set(state.recursos, key, value)
    },

    setPeriodoFiscal (state, payload) {
      state.periodo_fiscal = payload
    },

    setPeriodoFiscalId (state, id) {
      Vue.set(state.periodo_fiscal, 'id', id)
    },

    setFuenteFinanciamiento (state, payload) {
      console.log(payload.clave)
      state.fuente_financiamiento = payload
    },

    setTab (state, payload) {
      state.selected = payload
    },

    setRegistros (state, payload) {
      state.registros = payload
    }
  },

  actions: {
    async uploadLayout ({ state, commit }, payload) {
      console.log(state.fuente_financiamiento)
      let retval = {}

      const formData = new FormData()

      formData.append('file', payload.file)
      //
      formData.append('fuente_financiamiento_id', state.fuente_financiamiento.id)

      //
      formData.append('save_file', payload.save_file ? 1 : 0)
      //
      formData.append('overwrite', payload.overwrite ? 1 : 0)

      try {
        commit('setImportacion', { key: 'loading', value: true })

        const { data } = await service.post(IMPORTAR_LAYOUT, formData)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      } finally {
        commit('setImportacion', { key: 'loading', value: false })
      }

      return retval
    },

    async getAll ({ state, commit }, payload) {
      let retval = {}

      try {
        commit('setRecursos', { key: 'loading', value: true })

        payload.fuente_financiamiento_id = state.fuente_financiamiento.id

        const { data } = await API_AMPLIACIONES_REDUCCIONES.all(payload)

        retval = data

        commit('setRegistros', retval.data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      } finally {
        commit('setRecursos', { key: 'loading', value: false })
      }

      return retval
    },

    async create ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await API_AMPLIACIONES_REDUCCIONES.create(payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async update ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await API_AMPLIACIONES_REDUCCIONES.update(payload.id, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async delete ({ state, dispatch }, id) {
      let retval = {}

      try {
        const { data } = await API_AMPLIACIONES_REDUCCIONES.delete(id)

        retval = data

        dispatch('getAll', state.importacion)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async autorize ({ state, commit, dispatch }, payload) {
      let retval = {}

      try {
        commit('setRecursos', { key: 'loading', value: true })

        const { data } = await service.post(`api/ampliacion-reduccion/${payload.id}/autorizar`, {
          periodo_fiscal_id: payload.id
        })

        retval = data

        dispatch('getAll', state.importacion)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      } finally {
        commit('setRecursos', { key: 'loading', value: false })
      }

      return retval
    }
  }
}
