import Resource from '@/api/Resource'
import service from '@/api/service'

const resource = new Resource('/api/saldos', 'saldoModule')

export default {
  namespaced: true,

  state: {
    reporte: [],

    params: {
      periodo_fiscal_id: null,
      fecha_inicial: null,
      fecha_final: null,
      clave_inicial: null,
      clave_final: null
    },

    isGettingResource: false
  },

  getters: {
    hasReporte: state => {
      return state.reporte.data
    },

    getReporte: ({ reporte }, getters) => {
      return getters.hasReporte ? reporte.data : []
    },

    getParams: state => {
      return state.params
    }
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setReporte (state, reporte) {
      state.reporte = reporte
    },

    setPeriodoFiscalId (state, payload) {
      state.params.periodo_fiscal_id = payload
    },

    setFechaInicial (state, payload) {
      state.params.fecha_inicial = payload
    },

    setFechaFinal (state, payload) {
      state.params.fecha_final = payload
    },

    setClaveInicial (state, payload) {
      state.params.clave_inicial = payload
    },

    setClaveFinal (state, payload) {
      state.params.clave_final = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    }
  },

  actions: {
    async getReporteAuxiliar ({ commit }, payload) {
      let retval = {}

      try {
        const { data } = await resource.get('api/reportes/auxiliar-cuentas', {
          periodo_fiscal_id: payload.periodo_fiscal_id,
          clave_inicial: payload.clave_inicial,
          clave_final: payload.clave_final,
          fecha_inicial: payload.fecha_inicial,
          fecha_final: payload.fecha_final
        }, 'setIsGettingResource')

        retval = data

        commit('setReporte', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportReporte ({ context }, payload) {
      let retval = {}

      try {
        // Duplicamos la llamada para verificar primero si no hay un
        // error y mostrar el mensaje correcto al usuario.
        // Hacemos la petición get sin especificar que se recibe un blob
        await service({
          _method: 'GET',
          url: 'api/reportes/auxiliar-cuentas/export',
          params: {
            periodo_fiscal_id: payload.periodo_fiscal_id,
            clave_inicial: payload.clave_inicial,
            clave_final: payload.clave_final,
            fecha_inicial: payload.fecha_inicial,
            fecha_final: payload.fecha_final
          }
        })
        // Si no existe error la excepción no se lanza y podemos
        // obtener el blob correctamente
        const { data } = await service({
          _method: 'GET',
          url: 'api/reportes/auxiliar-cuentas/export',
          responseType: 'blob',
          params: {
            periodo_fiscal_id: payload.periodo_fiscal_id,
            clave_inicial: payload.clave_inicial,
            clave_final: payload.clave_final,
            fecha_inicial: payload.fecha_inicial,
            fecha_final: payload.fecha_final
          }
        })
        retval = data
      } catch (error) {
        retval = { error: true, message: error.message, ...error.response.data }
      }

      return retval
    }
  }
}
